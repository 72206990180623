Array.prototype.sum = function (prop) {
  var sum = 0
  var total = 0
  for ( var i = 0, _len = this.length; i < _len; i++ ) {
    total += this[i][prop]
  }
  return total.toFixed(2)
};

Array.prototype.clean = function(deleteValue) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == deleteValue) {
      this.splice(i, 1);
      i--;
    }
  }
  return this;
};

Array.prototype.map_val = function(key, value, asked) {
  var new_arr = this.map(function(a) {
                          if (a[key] == value) {
                            return a[asked];
                          }
                         });
  return new_arr;
};

String.prototype.formatCurrency = function(){
  var formated = this.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  return formated
};

// Counting total due on pre-flight report view

$.fn.extend({

  countTotalDue: function(){
    var $discountInputs = $(this);
    $discountInputs.each(function(){

      var $discountInput = $(this)
      $discountInput.on('focusout', function(){

        var customerId = $(this).attr('data-customer');

        var $totalDue = $("#" + customerId + '-total-due');

        var $subTotalDue = $("#" + customerId + '-sub-total-due');
        var baseSubTotalDue = parseFloat($subTotalDue.attr('data-base-sub-total-due'))

        var $customerBalance = $("#" + customerId + '-customer-balance');
        var customerBalance = parseFloat($customerBalance.attr('data-customer-balance'));

        var $salesTax = $('#' + customerId + '-sales-tax');
        var salesTax = parseFloat($salesTax.attr('data-sales-tax'));

        var discountInDollars = $discountInput.val()

        if ( (discountInDollars <= 0) ||
             (discountInDollars == undefined) ||
             (discountInDollars == "") ){
          $discountInput.val(parseFloat(0).toFixed(2))
          discountInDollars = 0
        } else if ( (discountInDollars - baseSubTotalDue) > 0 ) {
          $discountInput.val(baseSubTotalDue.toFixed(2))
          discountInDollars = baseSubTotalDue
        }

        if (customerBalance == undefined) {
          customerBalance = 0
        }
        var newSubTotalDueValue = baseSubTotalDue - discountInDollars;
        var newTotalDueValue = newSubTotalDueValue - customerBalance + salesTax;

        if (newTotalDueValue < 0) {
          newTotalDueValue = 0
        }

        $totalDue.html("$" + newTotalDueValue.toFixed(2))
        $subTotalDue.html("$" + newSubTotalDueValue.toFixed(2))
      });
    });
    return $discountInputs;
  }, //countTotalDue

  formatCurrencyInput: function(){
    var $input = $(this)
    $input.focusout(function(){
      $(this).val(parseFloat($(this).val()).toFixed(2))
    });
    return $input
  }, //formatCurrency

  blockFromEnterSubmistion: function() {
    var $form = $(this)
    $form .on("keypress", function (e) {
      if (e.keyCode == 13) {
          return false;
      }
    });
    return $form
  },

  countTotalDueSummary: function() {
    var $form = $(this)
    $form.find('input.discount').on('focusout', function(){
      var $totalDues = $form.find('.customer-total-due')
      var $paysBy = $form.find('.customer-pays-by')

      var totalDuesArr = []
      $totalDues.each(function(){
        var customer_id = $(this).attr('data-customer-id')
        var value = parseFloat($(this).text().replace("$", ""))
        totalDuesArr.push({'total-due': value,
                           'customer-id': customer_id})
      })
      var customerTypesArr = []
      $paysBy.each(function(){
        var customer_id = $(this).attr('data-customer-id')
        var value = $(this).attr('data-customer-type')
        customerTypesArr.push({'pays-by': value,
                               'customer-id': customer_id})
      })

      // Grand Total
      var grantTotalDue = totalDuesArr.sum('total-due').formatCurrency()
      $('#grand-total').text("$" + grantTotalDue)

      // Trials
      var trialIds = customerTypesArr.map_val('pays-by','Trial','customer-id')
                                     .clean(undefined);
      var totalDuesTrialArr = totalDuesArr.filter(function(obj){
                                                    return trialIds.includes(obj['customer-id'])
                                                  })
      var trialTotalDue = totalDuesTrialArr.sum('total-due').formatCurrency()
      $('#grand-trial').text("$" + trialTotalDue)

      // CreditCard
      var cclIds = customerTypesArr.map_val('pays-by','Credit Card','customer-id')
                                   .clean(undefined);
      var totalDuesCCArr = totalDuesArr.filter(function(obj){
                                                    return cclIds.includes(obj['customer-id'])
                                                  })
      var ccTotalDue = totalDuesCCArr.sum('total-due').formatCurrency()
      $('#grand-cc').text("$" + ccTotalDue)

      // Check
      var checklIds = customerTypesArr.map_val('pays-by','Check','customer-id')
                                      .clean(undefined);
      var totalDuesCheckArr = totalDuesArr.filter(function(obj){
                                                    return checklIds.includes(obj['customer-id'])
                                                  })
      var checkTotalDue = totalDuesCheckArr.sum('total-due').formatCurrency()
      $('#grand-check').text("$" + checkTotalDue)


      // Pre-paid
      var pplIds = customerTypesArr.map_val('pays-by','Pre-Paid','customer-id')
                                      .clean(undefined);
      var totalDuesPpArr = totalDuesArr.filter(function(obj){
                                                    return pplIds.includes(obj['customer-id'])
                                                  })
      var ppTotalDue = totalDuesPpArr.sum('total-due').formatCurrency()
      $('#grand-pre-paid').text("$" + ppTotalDue)


      // Other
      var achIds = customerTypesArr.map_val('pays-by','ACH','customer-id')
                                   .clean(undefined);

      var unknowIds = customerTypesArr.map_val('pays-by','Unknown','customer-id')
                                      .clean(undefined);

      var otherIds = achIds + unknowIds
      var totalDuesOtherArr = totalDuesArr.filter(function(obj){
                                                    return otherIds.includes(obj['customer-id'])
                                                  })
      var otherTotalDue = totalDuesOtherArr.sum('total-due').formatCurrency()
      $('#grand-other').text("$" + otherTotalDue)

    });
    return $form
  }
});

$(function() {
  // go to top invoice
  if ( $('form#pre-flight-form').length > 0 ) {
    $('form#pre-flight-form').blockFromEnterSubmistion()
    $('input.discount')[0].focus()
    $('input.discount').countTotalDue().formatCurrencyInput().keyup()
    $('form#pre-flight-form').countTotalDueSummary()
    $('input.discount').focus()
    $('[data-toggle="tooltip"]').tooltip();
  }

  if ( $('#post-flight-report').length > 0 ) {
    var $root = $('html, body');
    $('a.anchor').click(function() {

        var scrollTo = $( $.attr(this, 'href') ).offset().top
        $root.animate({
            scrollTop: scrollTo
        });
        return false;
    });
  }
});
