$(function() {
  set_other_reason_state();
});

function set_other_reason_state() {
  $("#activate_select").on('change', function() {
    optionSelected = $("option:selected", this);
    other_reason_input = $('#activate_other_reason')
    toggle_other_reason_state(optionSelected, other_reason_input)
  });

  $("#deactivate_select").on('change', function() {
    optionSelected = $("option:selected", this);
    other_reason_input = $('#deactivate_other_reason')
    toggle_other_reason_state(optionSelected, other_reason_input)
  });
}

function toggle_other_reason_state(optionSelected, other_reason_input) {
  if (optionSelected.val() == '') {
    other_reason_input.prop("disabled", false);
  } else {
    other_reason_input.prop("disabled", true);
  }
}
