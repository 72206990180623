/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

function sumLineTotals() {
  let $sum = 0;
  $('td.line-total').each(function(index, line_total) {
    const partial = parseFloat($(line_total).text().replace( /^\D+/g, ''));
    $sum += partial;
  });
  $('.sub_total.value').each((index, field) => $(field).html('$' + $sum.toFixed(2)));
};

$(function() {
  $('#count-it-button').on('click', function() {
    sumLineTotals();
  });
});
