/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  const browse_file_button_label = $('#artwork-form #browse-file-button span');
  const browse_file_button       = $('#artwork-form #browse-file-button input');
  const upload_file_button       = $('#artwork-form input#upload-file-button');
  const file_text_field          = $('#artwork-form input[name=file_name]');
  const file_input_field         = $('#artwork-form input[name=artwork]');


  if (browse_file_button_label) {

    const enable_file_button = function() {
      $(browse_file_button_label).removeClass('disabled');
      $(browse_file_button).removeAttr('disabled');
    };

    const disable_upload_button = () => $(upload_file_button).attr('disabled', true);

    const enable_upload_button = () => $(upload_file_button).removeAttr('disabled');

    enable_file_button();
    disable_upload_button();

    file_input_field.change(function() {
      const full_path = this.value;
      const filename = full_path.replace(/^.*[\\\/]/, '');
      file_text_field.val(filename);

      enable_upload_button();
    });
  }
});

