/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
require('select2/dist/js/select2.full');
import 'select2/dist/css/select2.min.css'

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// cannot load datatables without imports-loader(0.8.0) lib, recommended way by:
// https://stackoverflow.com/questions/57964095/rails-6-webpack-datatables-jquery
require('imports-loader?define=>false!datatables.net')(window, $);
require('imports-loader?define=>false!datatables.net-bs')(window, $);
import 'datatables.net-bs/css/dataTables.bootstrap.min'

import moment from 'moment'
window.moment = moment

$.fn.datetimepicker = require('eonasdan-bootstrap-datetimepicker')
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css'

import 'jquery-flexdatalist/jquery.flexdatalist'
import '../src/data_tables.js';
import '../src/report.js';
import '../src/xero';
import './activity';
import './ad_hoc_invoice';
import './artwork_upload';
import './bootstrap.js';
import './customer_file';
import './list_orders';
import './list_file_download_log';
import './activation_link';
import './response_tracker_usage';
// // ./lead_count_tool

import InitLeadCount from './modules/lead_count_tool/init.js'
import LeadMap from './modules/lead_count_tool/lead_map.js'
import LeadToggleMarkers from './modules/lead_count_tool/lead_toggle_markers.js'
import LeadRadius from './modules/lead_count_tool/lead_radius.js'
import LeadForm from './modules/lead_count_tool/lead_form.js'

window.InitLeadCount = InitLeadCount
window.LeadMap = LeadMap
window.LeadToggleMarkers = LeadToggleMarkers
window.LeadForm = LeadForm
window.LeadRadius = LeadRadius

// TODO: This may be not needed, please check all places where payment-logs-modal is used.
$(function() {
  var options = {
    backdrop: true,
    show: false
  }
  $('.payment-logs-modal').modal(options)
})

window.delay = (function delay() {
  var timer;
  timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
}());

$(function() {
  $(".last-download-info").tooltip();
});

$(() => {
  $("#day").datetimepicker({ format: "YYYY-MM-DD" });

  $("#invoice_summaries_day_from").datetimepicker({
    format: "YYYY-MM-DD",
    allowInputToggle: true
  });
  $("#invoice_summaries_day_to").datetimepicker({
    format: "YYYY-MM-DD",
    allowInputToggle: true
  });

  $("#pre_flight_from_date, #pre_flight_to_date").datetimepicker({
    format: "YYYY-MM-DD",
    useCurrent: false,
    allowInputToggle: true
  });
});
