/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  const file_text_field          = $('.activity-form input[name=file_name]');
  const file_input_field         = $('.activity-form input#activity_file');
  file_input_field.change(function() {
    const full_path = this.value;
    const filename = full_path.replace(/^.*[\\\/]/, '');
    
    file_text_field.val(filename);
  });
});
