/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let initializeLeadCountTool;
export default initializeLeadCountTool = () => $(function() {
  if ($('#lead-search-container').length < 1) { return; }

  // disable search type for not selected tab
  $('.nav.nav-tabs a').on('click', function() {
    $('#address-lead-search-container .search-type').prop('disabled', "disabled");
    $('#zipcode-lead-search-container .search-type').prop('disabled', "disabled");
    $('#fips-lead-search-container .search-type').prop('disabled', "disabled");
    $($(this).attr('href') + " .search-type").prop('disabled', "");
  });

  // preselect tab with proper search-type
  const orderListType = $('#list-order-search-type').attr('data-search-type');

  if (orderListType === 'fips') {
    $('a[href="#fips-lead-search-container"]').click();
  } else if (orderListType === 'zip') {
    $('a[href="#zipcode-lead-search-container"]').click();
  } else if (orderListType === 'address') {
    $('a[href="#address-lead-search-container"]').click();
  }


  // initialize leadMap
  if (!window.leadMap) {
    window.leadMap = new LeadMap();
    window.leadMap.addMarkersToggleController('#toggle-map-markers');
    window.leadMap.initMap();
  }

  const $spinner = $('#loading-spinner');

  $.ajaxSetup({beforeSend(xhr) {
    xhr.setRequestHeader('Authorization', window.leadApiToken);
  }
  });

  $('.cities-with-states-input').keyup(function() {
    const cities_with_states = $.trim($(this).val()).replace(/\n\r?/g, '%0D%0A');
    delay((function() {
      const query = 'cities_with_states=' + cities_with_states;
      const url = window.leadApiUrlHost + '/api/v2/zip_codes?' + query;
      $(".cities-loading").show(500);

      $.ajax({
        url,
        success(response) {
          const zip_codes = response.zip_codes.join(", ");
          $('.cities-zip-codes-result').html(zip_codes);
          $('.cities-error-container').html("");
        },
        error(response) {
          $('.cities-zip-codes-result').html("");
          $('.cities-error-container').html(JSON.parse(response.responseText).errors);
        },
        complete() {
          $(".cities-loading").hide(0);
        }
      });
    }), 500);
  });

  $('#find-zip-codes-ok-button').click(function() {
    let newZipCodeValue;
    const foundZipCodes = $('.cities-zip-codes-result').html();
    const zipCodesFormInput = $("#zipcode-input");
    const currentZipCodeValue = zipCodesFormInput.val();
    if (currentZipCodeValue.length > 0) {
      newZipCodeValue = currentZipCodeValue + ", " + foundZipCodes;
    } else {
      newZipCodeValue = foundZipCodes;
    }

    zipCodesFormInput.val(newZipCodeValue);
    const zipCodes = LeadForm.sanitizeZipCodes(newZipCodeValue);

    if (zipCodes) {
      LeadForm.getZipGeometryAndDraw(zipCodes, window.leadMap);
    } else {
      window.leadMap.removeAllPolygons();
    }
  });

  $('.flexdatalist-county').flexdatalist({
    searchContain: false,
    textProperty: '{county_name}, {fips}',
    valueProperty: 'fips',
    valuesSeparator: ', ',
    minLength: 0,
    focusFirstResult: true,
    selectionRequired: true,
    maxShownResults: 999,
    groupBy: 'state_name',
    visibleProperties: [
      'state_name',
      'county_name',
      'fips'
    ],
    searchIn: [
      'state_name',
      'county_name',
      'fips'
    ],
    url: window.leadApiUrlHost + '/api/v2/fips.json',
    chainedRelatives: true,
    relatives: '#relative'
  },

    $('.flexdatalist-state').flexdatalist({
      searchContain: false,
      textProperty: '{postal_code}, {name}',
      valueProperty: 'postal_code',
      valuesSeparator: ', ',
      minLength: 2,
      focusFirstResult: true,
      selectionRequired: true,
      searchIn: [
        'postal_code',
        'name'
      ],
      url: window.leadApiUrlHost + '/api/v2/states.json'
    })
  );

  if ($('#zipcode-lead-search-form').length > 0) {
    const $leadFormZipCode = new LeadForm($('#zipcode-lead-search-form'));
    $leadFormZipCode.zipCodeInput = $('#zipcode-lead-search-form').find('#zipcode-input');

    if ($('#zipcode-lead-search-container').length > 0) {
      $leadFormZipCode.bindSubmit('#submit-btn', () => $('#zipcode-lead-search-container').hasClass('active'));
    } else {
      $leadFormZipCode.bindSubmit('#lead-submit-btn', () => $('#list_type_option_zip_codes').is(':checked'));
    }

    if ($spinner.length > 0) {
      $leadFormZipCode.spinner = $spinner;
    }

    $leadFormZipCode.zipCodeInput.on('keyup', function(){
      const zipCodes = LeadForm.sanitizeZipCodes(this.value);

      if (zipCodes) {
        window.leadMap.zoomOutUsa();
        LeadForm.getZipGeometryAndDraw(zipCodes, window.leadMap);
      } else {
        window.leadMap.removeAllPolygons();
      }
    });
  }

  if ($('#address-lead-search-form').length > 0) {
    const $leadFormAddress = new LeadForm($('#address-lead-search-form'));
    if ($('#address-lead-search-container').length > 0) {
      $leadFormAddress.bindSubmit('#submit-btn', () => $('#address-lead-search-container').hasClass('active'));
    } else {
      $leadFormAddress.bindSubmit('#lead-submit-btn', () => $('#list_type_option_mile_radius').is(':checked'));
    }

    if ($spinner.length > 0) {
      $leadFormAddress.spinner = $spinner;
    }
  }

  if ($('#fips-lead-search-form').length > 0) {
    const $leadFromFips = new LeadForm($('#fips-lead-search-form'));
    $leadFromFips.fipsCodeInput = $('#fips-lead-search-form').find('.flexdatalist-county');

    if ($('#fips-lead-search-container').length > 0) {
      $leadFromFips.bindSubmit('#submit-btn', () => $('#fips-lead-search-container').hasClass('active'));
    } else {
      $leadFromFips.bindSubmit('#lead-submit-btn', () => $('#list_type_option_counties').is(':checked'));
    }

    if ($spinner.length > 0) {
      $leadFromFips.spinner = $spinner;
    }

    $('.flexdatalist-county').on('select:flexdatalist', function(){
      const elem = $(this);
      let fipsCodes = elem.val();
      window.leadMap.requestAndDrawCounties(fipsCodes);
      window.leadMap.removeAllMarkers();
      window.leadMap.hideOverlayText();
      $('.fdl-remove').on('click', function(){
        window.leadMap.removeAllMarkers();
        fipsCodes = elem.val();
        window.leadMap.requestAndDrawCounties(fipsCodes);
      });
    });
  }
  if (new URL(window.location.href).searchParams.get('run') === 'true') { $('#submit-btn').click(); }
});
