
$(function() {
  if ($('.tablejs thead th').length > 7) {
        var order =  [[ 5, "asc" ]]
       } else {
        var order =  [[ 0, "desc" ]]
       }
  var table = $('.tablejs').DataTable({
    // ajax: ...,
    autoWidth: true,
    pagingType: 'full_numbers',
    processing: true,
    keys: true,
    "order": order,
    'pageLength': 50,
    // serverSide: true,
    'fnDrawCallback': function(){
      $('[data-toggle="tooltip"]').tooltip()
    }
    // Optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about available options.
    // http://datatables.net/reference/option/pagingType
  });

  var filters_ids = '#variance-min, #variance-max, #s-deviation-min, #s-deviation-max'
  $(filters_ids).keyup( function() {
    filterRecordsNormally();
    table.draw();
  } );

  $('#filter-zeros').click ( function() {
    // Change input value to filter records normally,
    // not by outlying values
    filterRecordsNormally();
    table.draw();
  } );

  $('#filter-by-outlying-variance').click(function() {
    var text = $(this).text().trim()
    if (text == 'Show records with outlying variance') {
      // Reset previous variance filter and use the filtering for
      // values with abs(variance) >= 3
      clearInputValues()
      filterRecordsByOutlyingValues();
      $(this).text('Show all records');
      table.draw();
    } else {
      clearInputValues();
      filterRecordsNormally();
      $(this).text('Show records with outlying variance');
      table.draw();
    }
  })

  $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
          var min = parseFloat( $('#variance-min').val(), 10 );
          var max = parseFloat( $('#variance-max').val(), 10 );
          row_index = $('.tablejs thead th').index($('.tablejs thead th').filter(":contains(variance)")[0])
          var variance = parseFloat( data[row_index] ) || 0;
          if ( ( isNaN( min )      && isNaN( max ) ) ||
               ( isNaN( min )      && variance <= max ) ||
               ( min <= variance   && isNaN( max ) ) ||
               ( min <= variance   && variance <= max )
              )
          {
              return true;
          }
          return false;
      }
  );

  $.fn.dataTable.ext.search.push(
    function( settings, data, dataIndex ) {
      if ($('#outlying-variance-switch').val() == 0) {
        return true;
      }
      var min = -3;
      var max = 3;
      row_index = $('.tablejs thead th').index($('.tablejs thead th').filter(":contains(variance)")[0])
      var variance = parseFloat( data[row_index] ) || 0;
      if ( min <= variance && variance >= max)
      {
          return true;
      }
      return false;
    }
  );

  $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
          var min = parseFloat( $('#s-deviation-min').val(), 10 );
          var max = parseFloat( $('#s-deviation-max').val(), 10 );
          row_index = $('.tablejs thead th').index($('.tablejs thead th').filter(":contains(standard deviation)")[0])
          var s_deviation = parseFloat( data[row_index] ) || 0;
          if ( ( isNaN( min )      && isNaN( max ) ) ||
               ( isNaN( min )      && s_deviation <= max ) ||
               ( min <= s_deviation   && isNaN( max ) ) ||
               ( min <= s_deviation   && s_deviation <= max ) )
          {
              return true;
          }
          return false;
      }
  );

  $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
          var checked = $('#filter-zeros')[0].checked;
          var rows_with_counts = $('.tablejs thead th').filter(":contains(count)")
          var row_indexes = []

          rows_with_counts.each(function(){
            row_indexes.push($('.tablejs thead th').index($( this )))
          })

          var zero_present = false

          row_indexes.forEach(function(row_index){
            var value = parseFloat( data[row_index] );

            if ( !isNaN(value) && parseInt(value) == 0){
              zero_present = true
            }
          })

          if (checked) {
            if (zero_present) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }

      }
  );

})

function filterRecordsNormally() {
  $('#outlying-variance-switch').val(0)
}

function filterRecordsByOutlyingValues() {
  $('#outlying-variance-switch').val(1)
}

function clearInputValues() {
  $('#variance-min').val('')
  $('#variance-max').val('')
  $('#filter-zeros').prop("checked", false);
}


$(document).on('turbolinks:load', function(){
  var table = $('tablejs').DataTable({
    // ajax: ...,
    autoWidth: true,
    pagingType: 'full_numbers',
    processing: true,
    keys: true,
    'pageLength': 50,
    'order': [[ 0, "asc" ]]
    // Optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about available options.
    // http://datatables.net/reference/option/pagingType
  });
});
