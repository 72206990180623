/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

// Keep tabs after page refresh
$(function() {
  $('#xero-tabs a').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  $('ul.nav-tabs > li > a').on('show.bs.tab', function(e) {
    const id = $(e.target).attr('href').substr(1);
    window.location.hash = id;
  });

  const {
    hash
  } = window.location;

  $('#xero-tabs a[href="' + hash + '"]').tab('show');
});

