/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const checkDistanceAlert = function() {
  if (($("#list_order_order_by option:contains('distance'):selected").length !== 0) &&
     ($('input[name=search_type]:checked')[0].id !== 'search_type_address')) {
    $("#distance-alert").show();
  } else {
    $("#distance-alert").hide();
  }
};

const disableDistanceOption = function() {
  if ($('input[name=search_type]:checked')[0].id === "search_type_address") {
    $("#list_order_order_by option:contains('distance')").attr("disabled", false);
  } else {
    $("#list_order_order_by option:contains('distance')").attr("disabled", true);
  }
};

$(function() {
  const create_files = $('span#should_create_files_group');

  $('input#dev').on('change', function() {
    if (this.checked) {
      create_files.show();
    } else {
      create_files.hide();
      create_files.find('input').attr('checked', false);
    }
  });

  if ($('input[name=search_type]').length && $("#list_order_order_by option:contains('distance')").length) {
    disableDistanceOption();
    checkDistanceAlert();
  }

  $('input[name=search_type]').on('change', function() {
    disableDistanceOption();
    checkDistanceAlert();
  });

  $("#list_order_order_by").on('change', function() {
    checkDistanceAlert();
  });

  $("form[id^='edit_list_order_']").on('submit', function() {
    if ($('#distance-alert').is(":visible")) {
      $('html').animate({ scrollTop: $('#distance-alert').offset().top }, 1000, () => $('#distance-alert').effect('shake', {distance: 2}));
      return false;
    }
  });

  $("#find-zip-codes-cancel-button").on('click', function() {
    $("#find-zip-codes-modal").modal('toggle');
  });
});

const copyListner = async (event) => {
  event.preventDefault();

  const { customerAccountId, listOrderId } = event.currentTarget.dataset;

  const infoCall = async () => {
    const response = await fetch(`/list_orders/copy_into_clipboard?customer_account_id=${customerAccountId}&list_order_id=${listOrderId || ""}`);
    if (response.ok) return response.text();

    throw new Error(`Status code error: ${response.status}`);
  };

  try {
    // Different copy execution for Safari
    if (navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1) {
      await navigator.clipboard.write([new window.ClipboardItem({ "text/plain": infoCall() })])
        .then(() => { alert("Successfully copied to the clipboard"); });
    } else {
      const infoResponse = await infoCall();
      await navigator.clipboard.writeText(infoResponse);
      alert("Successfully copied to the clipboard");
    }
  } catch (err) {
    alert("Copy to clipboard failed!");
  }
};

// Initalize event listeners for clicking copy button in the /customer_accounts/:id page
$(() => {
  const copyButtons = document.getElementsByClassName("copy-order-info");

  for (let i = 0; i < copyButtons.length; i++) {
    copyButtons[i].addEventListener("click", copyListner);
  }

  $("#day").datetimepicker({ format: "YYYY-MM-DD" });
});
